<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Create New Category</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

            <CForm>
              <CInput
                label="Code"
                v-model="code"
                placeholder ="Enter Category Code"
                horizontal
              /> 
              <CInput
                label="Description" 
                v-model="description"             
                placeholder="Enter category description"
                horizontal                
              />
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Mandatory</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-row>
                     <b-col sm="3"> 
                     <input type="radio" v-model="mandatory" value="true"> Yes
                     </b-col>
                     <b-col sm="3"> 
                     <input type="radio" v-model="mandatory" value="false"> No   
                     </b-col> 
                     <b-col sm="3"></b-col>                    
                     </b-row>
                   </b-col>                  
              </b-row><br/>

              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button-group>
                         <router-link to="/notif/category" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="createNotifCategory()" variant="primary">Save</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
            </CForm>   

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>

<script>
 import AccountService from '@/api/AccountService.js'; 

  export default {    
    data() {
      return {  
          code:'',
          description:'', 
          mandatory: 'true',
          seen:false,
          msg :'',
          color:''          
        };  
    },
    created () {   
      
    },
    methods: {

      createNotifCategory(){ 
         
        let params = {}; var validate = [];
        params['code'] = this.code;
        params['description'] = this.description;       

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){  
            params['mandatory'] = (this.mandatory === 'true') ? true : false;  console.log(params); 
            this.createNewNotifCategory(params); 
        } else {
           this.msg   = 'Data Not Complete !'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);
      },      

      // getNotifCategoryByCode: function(code) { 
      //     AccountService.getNotifCategoryByCode(code).then(resp => {   

      //      }, error => {
      //       console.log('error');    
      //     });
      // },  

      createNewNotifCategory: function(params) { 
          AccountService.createNewNotifCategory(params).then(resp => {  console.log(resp);

            if(resp.message=='Success'){
                this.msg   = 'Create New Admin successfully'; 
                this.color = 'success';  this.seen  = true;
                this.code='';
                this.description='';
                this.mandatory='false'; 
              } else {
                this.msg   = !resp.data.message ? "Create New Notification Category Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;                
              }

            setTimeout( () =>  this.seen=false , 5000);  
            
           }, error => {
            console.log('error');    
          });
      },        
      
    }
  }
</script>